<template>
  <div class="mainContainer">
    <Navbar navTitle=" "></Navbar>
    <main style="max-width: 600px; margin: 20px auto 0; padding-bottom: 100px; padding: 20px;">
      <router-link to="./watchBalance" class="navLink">
        <span>批量监控</span>
        <van-icon name="arrow" />
      </router-link>
      
      <!-- <router-link to="./crossChains" class="navLink">
        <span>跨链申请</span>
        <van-icon name="arrow" />
      </router-link> -->
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
export default {
  components: {
    Footer,
  },
  data() {
    return {
    }
  },
  watch: {
  }
  
}
</script>
<style>
html, body, #app {
  height: 100%
}
</style>
<style lang="less" scoped>
.mainContainer {
  min-height: 100%;
  background: #3F3F3F;
}
.navLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 12px;
  padding: 10px 20px;
  color: #323233;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  background: #f7f8fa;
  border-radius: 99px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  &:hover {
    background: #ddd
  }
}

</style>
