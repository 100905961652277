
import axios from 'axios';

const fetch = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://miner-api.deepernetwork.cc/' : 'http://localhost:7001',
  timeout: 30000
})


export default fetch

