import axios from "axios";
import Vue from "vue";

const fetch = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://miner-api.deepernetwork.cc"
      : "https://w4l0ttpj-7007.asse.devtunnels.ms/",
  timeout: 30000,
  withCredentials: true,
});

fetch.interceptors.response.use(
  function (response) {
    if (response.data.success) {
      return response.data;
    }
    Vue.prototype.$Notify({
      type: "danger",
      message: window.$$i18n.t("resCommon." + response.data.reason),
    });
    console.log(response);
    if (response.data.reason == "notAuthenticated") {
      setTimeout(() => {
        window.localStorage.clear();
        window.location.href = "./login";
      }, 3000);
    }
    return Promise.reject(response.data.reason);
  },
  function (error) {
    Vue.prototype.$Notify({ type: "danger", message: "unknow error" });
    return Promise.reject(error);
  }
);

export default fetch;
