import "babel-polyfill";
import Vue from "vue";
import Web3 from "web3";
import App from "./App.vue";
import router from "./router";
import moment from "moment";
import VueI18n from "vue-i18n";
import "@/assets/encryptWithPublicKey.js";

import "@/theme/main.less";
import fetch from "./fetch";
import cFetch from "./cFetch";
import axios from "axios";
import DPRNavbar from "@/components/Navbar/Navbar";

import {
  Button,
  Dialog,
  Cell,
  CellGroup,
  NavBar,
  Field,
  Locale,
  NoticeBar,
  Icon,
  Popup,
  Toast,
  CountDown,
  Form,
  Tag,
  Notify,
  Loading,
  Slider,
  Tab,
  Tabs,
  Switch,
  Stepper,
  RadioGroup,
  Radio,
  Pagination,
  Step,
  Steps,
  Collapse,
  CollapseItem,
  List,
} from "vant";

import enUS from "vant/es/locale/lang/en-US";
import zhCN from "vant/es/locale/lang/zh-CN";

const components = {
  Button,
  Dialog,
  Cell,
  CellGroup,
  NavBar,
  Field,
  Locale,
  NoticeBar,
  Icon,
  Popup,
  Toast,
  CountDown,
  Form,
  Tag,
  Notify,
  Loading,
  Slider,
  Tab,
  Tabs,
  Switch,
  Stepper,
  RadioGroup,
  Radio,
  Pagination,
  Step,
  Steps,
  Collapse,
  CollapseItem,
  Pagination,
  List,
};
Object.values(components).forEach((comp) => {
  Vue.use(comp);
});
window.Web3 = Web3;

Vue.use(VueI18n);

Vue.prototype.$moment = moment;
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$Notify = Notify;
Vue.prototype.$Toast = Toast;

Vue.prototype.$fetch = fetch;
Vue.prototype.$cFetch = cFetch;
Vue.prototype.$axios = axios;
Vue.prototype.$tget = function (obj, lang) {
  return obj[lang];
};
Vue.prototype.$sleep = function (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
};
Vue.component("Navbar", DPRNavbar);

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: (function () {
    if (window.navigator.language.match(/cn/gi)) {
      return "cn";
    }
    if (window.navigator.language.match(/ko/gi)) {
      return "ko";
    }
    return "en";
  })(), // 设置地区
  messages: {
    cn: require("./lang/cn"),
    en: require("./lang/en"),
  },
});
window.$$i18n = i18n;
Vue.prototype.$$i18n = i18n;

(Vue.prototype.$BASEURL =
  process.env.NODE_ENV === "production"
    ? "https://miner-api.deepernetwork.cc/"
    : "http://localhost:7001/"),
  (Vue.prototype.$ct = function (key) {
    return i18n.messages[i18n.locale].common[key];
  });

Vue.prototype.$ctToast = function (key) {
  return this.$toast(this.$ct(key));
};

new Vue({
  i18n,
  router,
  mounted() {
    if (window.navigator.language.match(/cn/gi)) {
      Locale.use("zh-CN", zhCN);
    } else {
      Locale.use("en-US", enUS);
    }
    this.$watch("$i18n.locale", (newVal) => {
      if (newVal === "cn") {
        Locale.use("zh-CN", zhCN);
      } else {
        Locale.use("en-US", enUS);
      }
    });
  },
  render: (h) => h(App),
}).$mount("#app");
