<template>
  <div class="registerContainer">
    <Navbar navTitle="SIGNIN"></Navbar>
    <main>
      <h3 style="text-align: center; color: #fff">Miner Center</h3>
      <div class="formContainer">
        <van-form>
          <div style="padding-top: 20px">
            <van-field
              v-model="form.email"
              center
              clearable
              :label="t('email')"
              :placeholder="t('inputEmail')"
              name="email"
              type="email"
              :rules="[{ required: true }]"
            >
            </van-field>
          </div>
              
          <div style="height: 20px"></div>
          <div v-if="needTowFA">
            <van-field
              v-model="form.imgCode"
              center
              clearable
              :label="t('imgCode')"
              :placeholder="t('inputImgCode')"
              maxlength="30"
              autocomplete="off"
              name="imgCode"
            >
              <template #button>
                <img style="width: 100px" @click="imgCodeUrl = $BASEURL+'captcha?'+Date.now()" :src="imgCodeUrl" alt="">
              </template>
            </van-field>
            <van-field
            v-model="form.verifyCode"
            center
            clearable
            :label="t('VerificationCode')"
            :placeholder="t('inputCode')"
            maxlength="30"
            autocomplete="off"
            name="verifyCode"
            :rules="[{ required: true }]"
          >
            <template #button>
              <van-button v-if="codeCount > 0" size="small" native-type="button" type="primary">{{
                codeCount
              }}</van-button>
              <van-button
                :loading="codeLoading"
                v-else
                native-type="button"
                @click="getCode"
                size="small"
                type="primary"
                >{{t('Send')}}</van-button
              >
            </template>
          </van-field>
          </div>
          
          <van-field
            v-model="form.password"
            center
            clearable
            type="password"
            :label="t('Password')"
            :placeholder="t('inputPassword')"
            name="password"
          >
          </van-field>
          <div style="margin: 56px 0 16px;">
            <van-button :loading="submitLoading" @click="get2FA" round block type="info" native-type="submit">{{t('Sign In')}}</van-button>
          </div>
          <div style="display: flex;justify-content: space-between;align-items: center">
            <router-link style="font-size: 12px" to="/register">{{t('Sign Up')}}</router-link>
            <router-link style="font-size: 12px" to="/changepassword">{{t('forgetPsw')}}</router-link>
          </div>
        </van-form>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import jwt from 'jsonwebtoken';
import PhoneInput from '@/components/PhoneInput/PhoneInput'
const validateEmail = require('@/tools/validateEmail') 
import Footer from '@/components/Footer/Footer'
export default {
  components: {
    PhoneInput,
    Footer
  },
  data() {
    return {
      needTowFA: false,
      submitLoading: false,
      codeCount: 0,
      codeLoading: false,
      imgCodeUrl: '',
      form: {
        email: '',
        password: '',
        verifyCode: ''
      }
    }
  },
  methods: {
    get2FA() {
      if (this.needTowFA) {
        this.onSubmit()
        return
      }
      this.submitLoading = true
      this.$fetch({
        url: '/user/2fa',
        params: {
          user: this.tabIndex == 0 ? this.form.phoneObj.formattedNumber : this.form.email,
        }
      }).then(res => {
        if (res.data && res.data.status == 'OPEN') {
          this.imgCodeUrl = this.$BASEURL+'captcha?'+Date.now();
          this.needTowFA = true
        } else {
          this.submitLoading = false
          this.onSubmit()
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    getCode() {
      if (!this.form.imgCode) {
        return this.$ctToast("请输入图片验证码");
      }
      if (!validateEmail(this.form.email)) {
        return this.$ctToast("请输入正确的邮箱地址");
      }
      
      this.codeLoading = true;
      this.$fetch({
        url: "/user/generateEmailCode",
        method: "POST",
        data: {
          imgCode: this.form.imgCode,
          phone: this.form.phoneObj.formattedNumber,
          email: this.form.email
        },
      })
        .then((res) => {
          this.startCodeCount();
        })
        .finally(() => {
          this.codeLoading = false;
        });
    },
    async startCodeCount() {
      this.codeCount = 60;
      while (this.codeCount > 0) {
        await this.$sleep(1000);
        this.codeCount--;
      }
    },
    t(params) {
      return this.$t(`login.${params}`)
    },
    onSubmit(e) {

      if (!validateEmail(this.form.email)) {
        return this.$ctToast('请输入正确的邮箱地址')
      }
      if (!this.form.password) {
        return this.$ctToast('请输入密码')
      }
      this.submitLoading = true
      this.$fetch({
        url: '/user/signIn',
        method: 'POST',
        data: {
          username: this.form.email,
          password: encryptWithPublicKey(this.form.password),
          verifyCode: this.form.verifyCode
        }
      }).then(res => {
        console.log(res)
        this.$toast.success()
        const token = res.token.replace('Bearer ', '')
        const tokenData = jwt.decode(token)
        window.localStorage.setItem('token', token)
        window.localStorage.setItem('tokenData', JSON.stringify(tokenData))
        if (this.firstLogin) {
          this.$router.push("/user")
        } else {
          this.$router.push("/user")
        }
        
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
</script>
<style>
html, body, #app {
  height: 100%
}
</style>
<style lang="less" scoped>
.registerContainer {
  min-height: 100%;
  background: #3F3F3F;
  main {
    padding: 0 10px;
  }
}
.formContainer {
  margin: 30px auto 0;
  width: 100%;
  max-width: 600px;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
}
@media screen and (max-width: 500px) {
  .formContainer {
    padding: 30px 10px;
  }
}
</style>
